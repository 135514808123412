import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Menu,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StoreIcon from '@material-ui/icons/Store';
import HomeIcon from '@material-ui/icons/Home';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logout, enqueueSnackbar } from 'common/store/actions';
import { getCurrentUser } from 'common/helpers/select';
import { colors } from 'common/constants';

const StyledMenu = withStyles({
  paper: {
    position: 'absolute',
    border: '1px solid #d3d4d5',
    display: 'block',
    transition: 'visibility .25s, opacity .25s',
    boxShadow:
      '0px 8px 11px -5px rgba(0,0,0,0.2), 0px 17px 26px 2px rgba(0,0,0,0.14), 0px 6px 32px 5px rgba(0,0,0,0.12)',
    outline: 'none !important',
    opacity: 1,
    float: 'left',
    borderRadius: '8px !important',
    overflowY: 'auto !important',
    zIndex: '2 !important',
    minWidth: '230px !important',
    width: '360px',
    background: colors.userMenu,
    padding: '15px',
    color: 'white',
    '& .userInfo': {
      marginLeft: '15px',
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledListItem = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    appearance: 'none !important',
    border: '0px !important',
    color: 'white !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    width: '100%',
    height: '80px',
    margin: '0 !important',
    textAlign: 'center !important',
    background: colors.userMenuDark,
    borderRadius: '5px',
  },
}))(ListItem);

const StyledListItemBlock = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    appearance: 'none !important',
    border: '0px !important',
    color: 'white !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    width: '100%',
    minHeight: '50px',
    margin: '0 !important',
    textAlign: 'center !important',
    background: colors.userMenuDark,
    borderRadius: '5px',
    '& span': {
      marginLeft: '10px',
    },
    '& p': {
      textAlign: 'left',
      fontWeight: '400',
    },
    '& strong': {
      fontSize: '16px',
    },
  },
}))(ListItem);

const StyledList = withStyles((theme) => ({
  root: {
    padding: '0 !important',
    '& .list-wrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    '& :focus': {
      outline: 'none !important',
    },
  },
}))(List);

const StyledListItemAvatar = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    display: 'flex',
    '& .MuiAvatar-colorDefault': {
      background: colors.userMenuDark,
    },
    '& .title': {
      fontSize: '16px',
    },
    '& span': {
      fontSize: '16px',
      fontWeight: '700',
    },
  },
}))(ListItem);

const StyledListItemText = {
  color: colors.userMenuDark,
  fontWeight: '700',
  lineHeight: '24px',
  fontSize: '16px',
};

export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const router = useRouter();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const key = new Date().getTime() + Math.random();
    dispatch(
      enqueueSnackbar({
        key,
        message: 'Logging out',
        options: {
          key,
          variant: 'error',
        },
      }),
    );

    dispatch(logout());
    router.push('/');
    window.location.reload();
  };

  let authLinks;
  if (user && !user.is_anonymous) {
    authLinks = (
      <Link href="/account" legacyBehavior>
        <StyledListItemAvatar button>
          <ListItemAvatar>
            <Avatar sizes="44" alt="" />
          </ListItemAvatar>
          <ListItemText primary={<Typography style={StyledListItemText}>{user.name}</Typography>} />
        </StyledListItemAvatar>
      </Link>
    );
  } else {
    authLinks = (
      <Link href="/auth/login" legacyBehavior>
        <StyledListItemAvatar button>
          <ListItemAvatar>
            <Avatar sizes="44" alt="" />
          </ListItemAvatar>
          <ListItemText primary={<Typography style={StyledListItemText}>Access your account</Typography>} />
        </StyledListItemAvatar>
      </Link>
    );
  }

  return (
    <Fragment>
      <IconButton aria-label="menuButton" color="inherit" onClick={handleClick}>
        <SvgIcon>
          <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
        </SvgIcon>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="dropdown-menu">
          <StyledList className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            {authLinks}
            <div className="list-wrapper">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Link href="/account" legacyBehavior>
                    <StyledListItem button>
                      <AccountBoxIcon />
                      <span>Account</span>
                    </StyledListItem>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link href="/favorites" legacyBehavior>
                    <StyledListItem button>
                      <FavoriteIcon />
                      <span>Favorites</span>
                    </StyledListItem>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link href="/account/store" legacyBehavior>
                    <StyledListItem button>
                      <StoreIcon />
                      <span>Store</span>
                    </StyledListItem>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link href="/account/new-integration" legacyBehavior>
                    <StyledListItem button>
                      <HomeIcon />
                      <span>Integrations</span>
                    </StyledListItem>
                  </Link>
                </Grid>

                <Grid item xs={12}>
                  <Link href="/faq" legacyBehavior>
                    <StyledListItemBlock button>
                      <EventNoteIcon />
                      <span>Help</span>
                    </StyledListItemBlock>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <StyledListItemBlock button onClick={() => handleLogout()}>
                    <ExitToAppIcon />
                    <span>Log out</span>
                  </StyledListItemBlock>
                </Grid>
              </Grid>
            </div>
          </StyledList>
        </div>
      </StyledMenu>
    </Fragment>
  );
}
